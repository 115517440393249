<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">{{stu == 'platform'?'平台':'我的'}}资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">{{stu == 'platform'?'平台':'我的'}}试卷/题库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">试卷详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="searchbox changetreestyle">
            <div style="margin-left: -30px;" class="flexcb searchbox">
              <h3>试卷基本信息</h3>
              <div>
                <!-- <el-button style="padding:8px 20px;" class="bgc-bv" @click="$router.back()">返回</el-button> -->
                <el-button
                  style="padding:8px 20px;"
                  class="bgc-bv"
                  v-show="stu=='platform'"
                  :disabled="(this.$route.query.addState == 0 && this.$route.query.notState == 0) ? false : true"
                  @click="salesState"
                >加入我的试卷</el-button>
              </div>
            </div>

            <div class="flexdc" style="align-items: flex-start">
              <el-form :inline="true" :rules="rules" :model="ruleForm" ref="basicInfoForm">
                <el-form-item label="试卷名称：" style="margin-right:20px" prop="paperName">
                  <p v-if="editBtn == true">{{ ruleForm.paperName }}</p>
                  <el-input v-model="ruleForm.paperName" v-else maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="试卷总分：" style="margin-right:20px">
                  <p>{{ ruleForm.paperTotalScore }}</p>
                </el-form-item>
                <el-form-item label="通过分数：" style="margin-right:20px" prop="paperScore">
                  <p v-if="editBtn == true">{{ ruleForm.paperScore }}</p>
                  <el-input-number
                    v-else
                    class="el-input-number"
                    :min="1"
                    placeholder="请输入试题分数"
                    v-model="ruleForm.paperScore"
                  ></el-input-number>
                </el-form-item>
              </el-form>
              <el-form :inline="true" :rules="rules" :model="ruleForm" ref="basicInfoForm">
                <el-form-item label="是否在平台销售：" style="margin-right:20px" prop="salesState">
                  <p
                    v-if="editBtn == true || paperOrgin == 20"
                  >{{ ruleForm.salesState == 10 ? "否" : "是" }}</p>
                  <el-select clearable v-model="ruleForm.salesState" v-else>
                    <el-option label="是" value="20"></el-option>
                    <el-option label="否" value="10"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="treeinput"
                  label="培训类型："
                  style="margin-right:20px"
                  prop="Trainingtype"
                >
                  <p v-if="editBtn == true">{{ ruleForm.Trainingtype }}</p>
                  <tree
                    v-else
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    size="small"
                    @clearParams="clearParams"
                    type
                    typeStu
                  />
                </el-form-item>
                <el-form-item v-if="editBtn && stu!='platform'">
                  <el-button style="padding:8px 20px" class="bgc-bv" @click="doEdit">编辑</el-button>
                </el-form-item>
                <el-form-item v-else v-show="stu!='platform'">
                  <el-button style="padding:8px 20px" class="bgc-bv" @click="doSave">保存</el-button>
                  <el-button style="padding:8px 20px" class="bgc-bv" @click="doGive">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-button
              v-show="subShow"
              style="padding:8px 20px;position: absolute;right:40px;top: 20px;"
              class="bgc-bv"
              @click="subject(parperId)"
            >提交审核</el-button>
          </div>
          <div class="framePage-scroll flexdc">
            <div class="bottom searchbox" style="padding-top:0; padding-right:1rem">
              <h3>试卷题目信息</h3>
              <div class="flexcb" style="align-items:flex-start">
                <el-form :inline="true">
                  <el-form-item label="题目内容:" style="margin-right:20px">
                    <el-input v-model="topiccontent" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="题目类型:" style="margin-right:20px">
                    <el-select v-model="TopictypeVal" clearable placeholder="请选择">
                      <el-option
                        v-for="item in Topictype"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button style="padding:8px 20px" class="bgc-bv" @click="chaxun">查询</el-button>
                  </el-form-item>
                </el-form>
                <div>
                  <el-button
                    v-show="stu!='platform'"
                    style="padding:8px 20px;aling-self:flex-start"
                    class="bgc-bv"
                    @click="addquestion(parperId)"
                  >增加试题</el-button>
                </div>
              </div>
            </div>
            <div class="ovy-a flex1" id="list-box">
              <div class="list" v-for="(item, index) in tableData" :key="index">
                <div class="list-item df">
                  <!-- <span
                  :class="tixing {item.questionType == 0? 'tixing1':item.questionType == 1 ? 'tixing2':item.questionType == 2 ? 'tixing3':item.questionType == 3 ? 'tixing4':''}"
                  >{{ $setDictionary("QUESTIONTYPE", item.questionType) }}</span
                  >-->
                  <span
                    class="tixing"
                    :class="{
                    tixing1: item.questionType == 0,
                    tixing2: item.questionType == 1,
                    tixing3: item.questionType == 2,
                    tixing4: item.questionType == 3,
                    tixing5: item.questionType == 4
                  }"
                  >{{ $setDictionary("QUESTIONTYPE", item.questionType) }}</span>
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ item.questionTitle }}</span>
                      <span class="number">(题目分值：{{ item.questionScore }})</span>
                    </div>
                    <div class="option" v-if="item.questionType == 0">
                      <span>A.{{ item.questionItem1 }}</span>
                      <span>B.{{ item.questionItem2 }}</span>
                      <span v-if="item.questionItem3">C.{{ item.questionItem3 }}</span>
                      <span v-if="item.questionItem4">D.{{ item.questionItem4 }}</span>
                      <span v-if="item.questionItem5">E.{{ item.questionItem5 }}</span>
                      <span v-if="item.questionItem6">F.{{ item.questionItem6 }}</span>
                    </div>
                    <div class="option" v-if="item.questionType == 1">
                      <span>A.{{ item.questionItem1 }}</span>
                      <span>B.{{ item.questionItem2 }}</span>
                      <span>C.{{ item.questionItem3 }}</span>
                      <span>D.{{ item.questionItem4 }}</span>
                      <span v-if="item.questionItem5">E.{{ item.questionItem5 }}</span>
                      <span v-if="item.questionItem6">F.{{ item.questionItem6 }}</span>
                    </div>
                    <div class="analysis">正确答案：{{ item.questionAnswer }}</div>
                    <div style="display:flex">
                      <p>答案解析：</p>
                      <span
                        class="analysis"
                        v-html="item.questionAnalysis"
                      ></span>
                    </div>
                    <div
                      class="df"
                      style=" flex-wrap: nowrap;justify-content: flex-end;align-items: flex-end;flex: 1;"
                      v-show="stu!='platform'"
                    >
                      <div style="white-space: nowrap;">
                        <el-button
                          style="padding:8px 20px"
                          v-show="
                          index !== 0 && TopictypeVal == '' && topiccontent == ''
                        "
                          @click="onSort(item.questionId, 'top')"
                        >上移</el-button>
                        <el-button
                          style="padding:8px 20px"
                          v-show="
                          index !== tableData.length - 1 &&
                            TopictypeVal == '' &&
                            topiccontent == ''
                        "
                          @click="onSort(item.questionId, 'bottom')"
                        >下移</el-button>
                        <el-button style="padding:8px 20px" @click="onEdit(item.questionId)">编辑</el-button>
                        <el-button
                          style="padding:8px 20px"
                          @click="onDel(item.questionId, item.paperId)"
                        >删除</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <TestPaper ref="TestPaper" @getnewdatap="getData" @subShows="subShows" />
    <Testpaper2 ref="Testpaper2" @getnewdata="getData" @subShows="subShows" />
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import TestPaper from "@/views/resourse/popup/TestPaper.vue";
import Testpaper2 from "@/views/resourse/popup/Testpaper2.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
export default {
  name: "testPaperEdit",
  components: {
    // Empty,
    PageNum,
    TestPaper,
    Testpaper2,
    tree
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      stu: "add",
      editBtn: true, //
      searchName: "",
      searchIdcard: "",
      searchPhone: "",
      centerDialogVisible: false,
      paperList: [],
      form: {
        type: "1",
        radio: null,
        checkList: [],
        optionList: [
          { mins: "", state: false },
          { mins: "", state: false },
          { mins: "", state: false },
          { mins: "", state: false }
        ],
        testnum: null,
        selectState: true
      },
      parperId: "",
      ruleForm: {
        paperTotalScore: "",
        paperName: "",
        paperScore: "",
        salesState: "",
        Trainingtype: ""
      },
      datatreeList: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      Topictype: [
        {
          value: "0",
          label: "单选题"
        },
        {
          value: "1",
          label: "多选题"
        },
        {
          value: "2",
          label: "判断题"
        },
        {
          value: "3",
          label: "填空题"
        },
        {
          value: "4",
          label:"论述题"
        }
      ],
      TopictypeVal: "",
      topiccontent: "",
      subjectlist: [], // 题目数据
      rules: {
        paperTotalScore: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        paperName: [{ required: true, message: "请输入", trigger: "blur" }],
        paperScore: [{ required: true, message: "请输入", trigger: "blur" }],
        salesState: [{ required: true, message: "请输入", trigger: "change" }],
        trainTypeId: [{ required: true, message: "请输入", trigger: "change" }],
        Trainingtype: [{ required: true, message: "请输入", trigger: "change" }]
      },
      notState: "",
      addState: "",
      subShow: false,
      params: {} // 培训类型参数
    };
  },
  created() {},
  mounted() {
    // const height = document.getElementById('list-box').offsetHeight;
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    subShows(params) {
      this.subShow = params;
    },
    init() {
      const query = this.$route.query;
      this.parperId = query.id;
      this.paperOrgin = query.paperOrgin;
      this.stu = query.stu;
      this.addState = query.addState || "";
      this.notState = query.notState || "";
      if (this.parperId) {
        this.getEdit(this.parperId);
        this.getData(-1);
      }
      this.getList();
    },
    // 培训类型
    getList() {
      this.$post("/sys/category/train-type/tree").then(ret => {
        if (ret.status == 0) {
          this.datatreeList = ret.data;
        }
      });
    },
    doSave() {
      const that = this;
      const basicInfoForm = this.$refs.basicInfoForm;
      let params = {
        paperTotalScore: that.ruleForm.paperTotalScore,
        paperName: that.ruleForm.paperName,
        paperScore: that.ruleForm.paperScore,
        salesState: that.ruleForm.salesState,
        paperId: that.parperId
      };
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      basicInfoForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.paperScore > this.ruleForm.paperTotalScore) {
            this.$message({
              message: "通过分数不能大于试卷总分数,请重新输入",
              type: "warning"
            });
          } else {
            that.$post("/biz/paper/modify", params).then(ret => {
              that.editBtn = true;
              that.getEdit(that.parperId);
              that.subShow = true;
              // let message = that.$message({
              //   showClose: true,
              //   duration: 3000,
              //   dangerouslyUseHTMLString: true,
              //   message:
              //     "您已修改试卷内容,是否立即<el-button type='text' style='color:red' @click='dangerouslyUseHTMLString'>提交审核</el-button>？"
              // });
              // message.$el.querySelector("el-button").onclick = () => {
              //   that
              //     .$post("/biz/paper/modifyAuditState", {
              //       resourceId: that.parperId,
              //       auditState: "20"
              //     })
              //     .then(ret => {
              //       console.log(ret);
              //       that.$router.push({
              //         path: "/web/testPaperList"
              //       });
              //     })
              //     .catch(() => {
              //       return;
              //     });
              // };
            });
          }
        }
      });
    },
    doGive() {
      this.getData(-1);
      this.editBtn = true;
    },
    optionFM(msg) {
      if (msg == 0) {
        return "选项A";
      } else if (msg == 1) {
        return "选项B";
      } else if (msg == 2) {
        return "选项C";
      } else if (msg == 3) {
        return "选项D";
      } else if (msg == 4) {
        return "选项E";
      } else if (msg == 5) {
        return "选项F";
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperId: this.parperId
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.topiccontent) {
        params.questionTitle = this.topiccontent;
      }
      this.doFetch({
        url: "/biz/paper/queryQuestionList",
        params,
        pageNum
      });
      this.getEdit(this.parperId);
    },
    chaxun() {
      this.getData(-1);
    },
    getEdit(paperId) {
      this.$post("/biz/paper/getPaperInfo", {
        paperId
      }).then(ret => {
        this.ruleForm = { ...this.ruleForm, ...ret.data };
        let name1 = "";
        let name2 = "";
        let name3 = "";
        let name4 = "";
        let name5 = "";
        if (ret.data.trainTypeId) {
          this.params.trainFormId = ret.data.trainTypeId;
          name1 = ret.data.trainTypeNamePath;
        }
        if (ret.data.postId) {
          this.params.postFormId = ret.data.postId;
          name2 = "/" + ret.data.postName;
        }
        if (ret.data.industryId) {
          this.params.industryFormId = ret.data.industryId;
          name3 = "/" + ret.data.industryNamePath;
        }
        if (ret.data.occupationId) {
          this.params.occFormId = ret.data.occupationId;
          name4 = "/" + ret.data.occupationNamePath;
        }
        if (ret.data.trainLevelId) {
          this.params.levelFormId = ret.data.trainLevelId;
          name5 = "/" + ret.data.trainLevelName;
        }

        let name = (name1 + name2 + name3 + name4 + name5).replace(/,/g, "-");
        this.ruleForm.Trainingtype = name;
      });
    },
    //
    doEdit() {
      this.editBtn = false;
    },
    // 上下移
    onSort(questionId, stu) {
      this.$post(
        stu == "top"
          ? "/biz/paper/moveUpQuestion"
          : "/biz/paper/moveDownQuestion",
        { questionId }
      )
        .then(ret => {
          if (ret.status == 0) {
            this.getData(-1);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 试题删除
    onDel(questionId, paperId) {
      const that = this;
      that
        .$confirm("你确定要从试卷中删除该试题吗", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that
            .$post("/biz/paper/deleteQuestion", { questionId, paperId })
            .then(ret => {
              if (ret.status == 0) {
                that.subShow = true;
                that.getData(-1);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          return;
        });
    },
    onEdit(questionId) {
      this.$refs.Testpaper2.showPopUp(questionId);
    },
    addquestion(parperId) {
      this.$refs.TestPaper.showPopUp(parperId);
    },
    //提交审核
    subject(paperId) {
      const that = this;
      that
        .$confirm("您已修改试卷内容,是否立即提交审核", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that
            .$post("/biz/paper/modifyAuditState", {
              resourceId: paperId,
              auditState: "20"
            })
            .then(ret => {
              if (ret.status == 0) {
                that.subShow = true;
                that.$message({
                  message: "提交成功",
                  type: "success"
                });
                setTimeout(() => {
                  that.$router.push({
                    name: "testPaperList",
                    params:{
                      refresh: true,
                      refName: 'first'
                    }
                  });
                }, 3000);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          return;
        });
    },
    // 加入
    salesState() {
      const paperId = this.$route.query.id;
      this.$post("/biz/paper/insertPlatformPaperToMine", {
        paperId
      })
        .then(res => {
          if (res.status == 0) {
            this.$message({
              type: "success",
              message: "已成功加入我的试卷"
            });
            // this.getData(-1);
            this.$router.push({
              name: "paperList",
              params: {
                refresh: true,
                refName:'first'
              }
            });
          }
        })
        .catch(err => {
          return;
        });
    }
  }
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
        }
      }
    }
  }
}
.searchbox {
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.tixing5{
  color: lightpink;
  background-color: #fff2fe;
}
.searchbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.changetreestyle {
  .treeinput {
    .el-input {
      flex: 0;
    }
  }
}
.treeinput {
  .el-form-item__content {
    width: 36rem;
  }
}
</style>
